import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue'),
  },
  {
    path: '/',
    name: 'Manage',
    component: () => import('../views/Manage.vue'),
    redirect: "/home",
    children: [

      {
        path: 'home',
        name: '账号列表',
        component: () => import('../views/Home.vue'),
      },
      {
        path: 'user',
        name: '短信列表',
        component: () => import('../views/User.vue'),
      }

    ]
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})



export default router
