import Vue from 'vue'
import Vuex from 'vuex'
// import router, {resetRouter} from "@/router";

Vue.use(Vuex)

const store = new Vuex.Store({
    state: {
        currentPathName: '',
          user:{}   
    },
    mutations: {
        setPath (state) {
            state.currentPathName = localStorage.getItem("currentPathName")
        },
        setCurrentUser(state,curUser){
            state.user = curUser;
        }
        // logout() {
        //     // 清空缓存
        //     localStorage.removeItem("user")
        //     localStorage.removeItem("menus")
        //     router.push("/login")
        //
        //     // 重置路由
        //     resetRouter()
        // }
    }
})

export default store
